import React, { useState } from "react";
import { getConfig } from "../../../utils/config";

const { adminApiUrl } = getConfig();

interface DeleteUserProps {
  userId: string;
  refreshUsers: () => void;
}

const DeleteUser: React.FC<DeleteUserProps> = ({ userId, refreshUsers }) => {
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});

  const handleDelete = async () => {
    setIsLoading({ [userId]: true });
    try {
      const response = await fetch(`${adminApiUrl}/users/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        refreshUsers();
      } else {
        const errorData = await response.json();
        console.error("Failed to delete user:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading({ [userId]: false });
    }
  };

  return (
    <button
      onClick={handleDelete}
      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
    >
      {isLoading[userId] ? "Deleting..." : "Delete"}
    </button>
  );
};

export default DeleteUser;
