import { Link, Outlet, useLocation } from "react-router-dom";
import { usePageLoading } from "../../hooks/usePageLoading";
import { useEffect, useMemo, useState } from "react";
import { getPlatform } from "../../services/telegramService";

export const Layout = () => {
  const { isPageLoading } = usePageLoading();
  const platform = useMemo(() => getPlatform(), [getPlatform]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  // if (platform !== "ios" && platform !== "android") {
  //   return <QRCodeComponent />;
  // }

  // if (isPageLoading) {
  //   return <Loader />;
  // }
  return (
    <>
      <Outlet />
      <nav
        className={`fixed bottom-1 left-0 w-full bg-[#2E3442] rounded-t-xl flex justify-around pt-2 shadow-lg items-center z-30 pb-4`}
      >
        <Link
          to="/"
          className={`w-16 h-14 text-white text-center flex flex-col items-center justify-between text-sm transition duration-300 px-4 rounded-lg py-1 ${
            activeTab === "/" ? "bg-[#282C36B2]" : ""
          }`}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5 0C11.4153 0 0 11.4153 0 25.5C0 39.5847 11.4153 51 25.5 51C39.5847 51 51 39.5847 51 25.5C51 11.4153 39.5821 0 25.5 0ZM33.6141 28.1061L23.4121 33.9958C21.405 35.1535 18.895 33.7051 18.895 31.3897V19.6103C18.895 17.2924 21.405 15.844 23.4121 17.0042L33.6141 22.8939C35.6211 24.0516 35.6211 26.9484 33.6141 28.1086V28.1061Z"
              fill="#92BFEA"
            />
          </svg>

          <span className="text-xs">Play</span>
        </Link>
        <Link
          to="/businesses"
          className={`w-16 h-14 text-white text-center flex flex-col items-center justify-between text-sm transition duration-300 px-4 rounded-lg py-1 ${
            activeTab === "/businesses" ? "bg-[#282C36B2]" : ""
          }`}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 55 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.4778 11.4479C44.901 9.55862 44.5237 6.87388 42.6352 5.45132C40.7467 4.02876 38.0622 4.40707 36.639 6.29631L2.57175 51.5215C1.14862 53.4108 1.52588 56.0955 3.41436 57.5181C5.30285 58.9406 7.98744 58.5623 9.41056 56.6731L43.4778 11.4479Z"
              fill="#92BFEA"
            />
            <path
              d="M42.403 12.8853L35.4698 7.66262C34.479 6.91626 33.0707 7.11442 32.3244 8.10523L25.0695 17.7362C24.3232 18.727 24.5214 20.1353 25.5122 20.8816L32.4454 26.1043C33.4362 26.8507 34.8445 26.6525 35.5908 25.6617L42.8457 16.0307C43.592 15.0399 43.3938 13.6316 42.403 12.8853Z"
              fill="#92BFEA"
            />
            <path
              d="M35.6701 12.6744L14.7497 1.05236C13.6588 0.444403 12.5848 1.87646 13.4697 2.75462L30.4282 19.5713C30.8875 20.0272 31.6475 19.9766 32.0393 19.4598L35.9977 14.2652C36.3962 13.7451 36.2375 12.9919 35.6633 12.6744H35.6701Z"
              fill="#92BFEA"
            />
            <path
              d="M32.4722 21.1048L53.3925 32.7269C54.4835 33.3348 55.5575 31.9027 54.6726 31.0246L37.7141 14.2079C37.2547 13.752 36.4948 13.8026 36.103 14.3194L32.1446 19.514C31.746 20.0342 31.9048 20.7873 32.4789 21.1048H32.4722Z"
              fill="#92BFEA"
            />
          </svg>

          <span className="text-xs">Mine</span>
        </Link>
        <Link
          to="/referrals"
          className={`w-16 h-14 text-white text-center flex flex-col items-center justify-between text-sm transition duration-300 px-4 rounded-lg py-1 ${
            activeTab === "/referrals" ? "bg-[#282C36B2]" : ""
          }`}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 66 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3092 5.95848C24.5205 5.95848 27.9454 9.38342 27.9454 13.5947C27.9454 15.8661 26.9413 18.0075 25.1899 19.4665C23.7309 20.6818 22.9997 22.5633 23.2499 24.4447C23.5001 26.3261 24.7024 27.9476 26.4312 28.7372C31.692 31.1386 35.091 36.419 35.091 42.1933V51.4543H5.52409V42.1933C5.52409 36.419 8.92303 31.1386 14.1839 28.7372C15.9126 27.9476 17.1149 26.3294 17.3651 24.4447C17.6154 22.56 16.8842 20.6818 15.4252 19.4665C13.6738 18.0075 12.6697 15.8694 12.6697 13.5947C12.6697 9.38342 16.0946 5.95848 20.3059 5.95848M20.3092 0.434387C13.0401 0.434387 7.14883 6.32567 7.14883 13.5947C7.14883 17.6598 8.99452 21.296 11.8898 23.7103C4.87745 26.9078 0 33.9786 0 42.19V56.9751H40.6183V42.19C40.6183 33.9786 35.7409 26.9078 28.7285 23.7103C31.6271 21.296 33.4695 17.6631 33.4695 13.5947C33.4695 6.32567 27.5782 0.434387 20.3092 0.434387Z"
              fill="#92BFEA"
            />
            <path
              d="M48.7257 14.407C51.8516 14.407 54.396 16.9514 54.396 20.0773C54.396 21.7638 53.6519 23.3528 52.3521 24.4349C50.8931 25.6502 50.1619 27.5316 50.4121 29.4131C50.6656 31.2945 51.8679 32.916 53.5934 33.7056C57.7754 35.613 60.4757 39.8114 60.4757 44.3996V51.4509H50.9645V39.9348C50.9645 34.7357 49.3203 29.816 46.2008 25.6762C46.0189 25.3935 45.8076 25.127 45.5737 24.8801C45.044 24.2334 44.4786 23.616 43.8872 23.0311C43.338 22.1408 43.0521 21.1269 43.0521 20.0741C43.0521 16.9481 45.5964 14.4038 48.7224 14.4038M48.7257 8.88293C42.5419 8.88293 37.5312 13.8936 37.5312 20.0773C37.5312 22.5177 38.3144 24.7728 39.6402 26.612C40.3063 27.2327 40.9237 27.9021 41.4891 28.6137C41.5151 28.6364 41.5378 28.6592 41.5638 28.6787C41.5573 28.6787 41.5541 28.6852 41.5476 28.6852C43.9847 31.7819 45.4404 35.6878 45.4404 39.9348V56.975H65.9998V44.3996C65.9998 37.4132 61.8503 31.4017 55.8875 28.6787C58.3506 26.625 59.9201 23.5348 59.9201 20.0773C59.9201 13.8936 54.9094 8.88293 48.7257 8.88293Z"
              fill="#92BFEA"
            />
          </svg>
          <span className="text-xs">Friends</span>
        </Link>
        <Link
          to="/tasks"
          className={`w-16 h-14 text-white text-center flex flex-col items-center justify-between text-sm transition duration-300 px-4 rounded-lg py-1 ${
            activeTab === "/tasks" ? "bg-[#282C36B2]" : ""
          }`}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.5 5.65028C41.0973 5.65028 51.3497 15.8999 51.3497 28.5C51.3497 41.1001 41.1001 51.3497 28.5 51.3497C15.8999 51.3497 5.65028 41.1001 5.65028 28.5C5.65028 15.8999 15.8999 5.65028 28.5 5.65028ZM28.5 0C12.7612 0 0 12.7583 0 28.5C0 44.2417 12.7583 57 28.5 57C44.2417 57 57 44.2417 57 28.5C57 12.7583 44.2388 0 28.5 0Z"
              fill="#92BFEA"
            />
            <path
              d="M19 40.4111V15.5913C19 15.2651 19.2604 15 19.5807 15H30.0726C32.0176 15 33.6537 15.2675 34.9787 15.8001C36.3037 16.3327 37.3062 17.0859 37.9836 18.0597C38.6611 19.0334 38.9999 20.1808 38.9999 21.4994C38.9999 22.4567 38.7948 23.3272 38.3823 24.1156C37.9721 24.9016 37.396 25.561 36.6563 26.0889C35.9166 26.6192 35.0525 26.9852 34.0639 27.187V27.4404C35.1608 27.4826 36.1586 27.7642 37.0573 28.2851C37.956 28.806 38.6704 29.5216 39.2027 30.4297C39.735 31.34 40 32.4076 40 33.6348C40 35.0567 39.6359 36.3214 38.9077 37.4312C38.1795 38.541 37.1472 39.4115 35.8083 40.0474C34.4694 40.6832 32.8748 41 31.0198 41H19.5807C19.2604 41 19 40.7349 19 40.4087V40.4111ZM25.9316 24.9228C25.9316 25.2489 26.192 25.514 26.5123 25.514H28.7753C29.3813 25.514 29.9182 25.4178 30.3837 25.2231C30.8492 25.0283 31.2133 24.7468 31.4737 24.3784C31.7364 24.01 31.8655 23.5595 31.8655 23.0269C31.8655 22.2221 31.582 21.6073 31.0174 21.1803C30.4529 20.7533 29.7362 20.5398 28.872 20.5398H26.51C26.1897 20.5398 25.9293 20.8049 25.9293 21.131V24.9274L25.9316 24.9228ZM25.9316 34.7728C25.9316 35.0989 26.192 35.364 26.5123 35.364H29.1739C30.3376 35.364 31.211 35.1411 31.7917 34.6977C32.3724 34.2542 32.6651 33.5949 32.6651 32.7244C32.6651 32.1143 32.5268 31.5981 32.2526 31.1758C31.9784 30.7535 31.5889 30.432 31.0866 30.2114C30.5842 29.9909 29.9782 29.8806 29.273 29.8806H26.5123C26.192 29.8806 25.9316 30.1457 25.9316 30.4719V34.7751V34.7728Z"
              fill="#92BFEA"
            />
          </svg>
          <span className="text-xs">Earn</span>
        </Link>
        <Link
          to="/airdrop"
          className={`w-16 h-14 text-white text-center flex flex-col items-center justify-between text-sm transition duration-300 px-4 rounded-lg py-1 ${
            activeTab === "/airdrop" ? "bg-[#282C36B2]" : ""
          }`}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 59 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.979 41.4023C24.4976 40.2246 24.1398 38.8261 23.0974 38.0848C19.9239 35.8293 17.9533 31.9466 18.3785 27.6407C18.9127 22.2202 23.2841 17.9064 28.6434 17.499C35.1955 16.9995 40.6844 22.2623 40.6844 28.8026C40.6844 32.6301 38.8046 36.016 35.9318 38.069C34.9154 38.7946 34.5369 40.1405 35.0425 41.2919C35.7711 42.9507 37.8246 43.5053 39.2739 42.4354C44.4829 38.5922 47.4076 31.7626 45.5848 24.4231C44.1432 18.6188 39.6551 14.0264 33.9405 12.528C22.8434 9.61529 12.874 18.0273 12.874 28.8026C12.874 34.465 15.6302 39.4754 19.8564 42.5301C21.2773 43.5579 23.266 43.019 23.9764 41.4023H23.979Z"
              fill="#92BFEA"
            />
            <path
              d="M29.0833 0.148382C13.1272 0.368283 0.136561 13.2991 0.00203345 29.0857C-0.0886262 39.803 5.66095 49.1952 14.2882 54.3455C15.9523 55.338 18.1193 54.6377 18.909 52.8814C19.5056 51.5533 18.9821 50.0111 17.7158 49.2762C9.9044 44.7422 4.97369 35.8477 6.38038 25.9897C7.67008 16.9564 16.312 8.10825 25.3984 6.56894C40.0794 4.08347 52.8595 15.2579 52.8595 29.3317C52.8595 37.7487 48.2856 45.1212 41.4715 49.1633C40.1233 49.9619 39.6086 51.6488 40.2491 53.0695C40.9831 54.7014 42.9981 55.3524 44.551 54.4381C53.2017 49.3514 59.001 40.0114 59.001 29.3317C59.001 13.0734 45.5658 -0.0802006 29.0804 0.148382H29.0833Z"
              fill="#92BFEA"
            />
          </svg>

          <span className="text-xs">Airdrop</span>
        </Link>
      </nav>
      {/* <DailyReward /> */}
    </>
  );
};
