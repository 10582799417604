import { useContext, useEffect, useState } from "react";
import { TopControl } from "../../components/TopControl";
import { getTelegramUser } from "../../services/telegramService";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";

const tgUserId = getTelegramUser().id;

export const Airdrop = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [taskStatus, setTaskStatus] = useState<{ [key: string]: boolean }>({});
  const notifyContext = useContext(NotifyContext);

  const fetchUnlockedTasks = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/admin/airdrop-tasks-status?userId=${tgUserId}`
      );
      const data = await response.json();

      if (!data.error) {
        const unlockedTasks = data.data.reduce((acc: any, task: any) => {
          acc[task.taskName] = true;
          return acc;
        }, {});
        setTaskStatus(unlockedTasks);
      }
    } catch (error) {
      console.error("Error fetching unlocked tasks:", error);
    }
  };
  useEffect(() => {
    fetchUnlockedTasks();
  }, []);

  const handleSendMessage = async (taskName: string) => {
    setIsLoading(true);
    let notify: NotifyMessage;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/admin/send-message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: tgUserId,
            taskName: taskName,
          }),
        }
      );

      const data = await response.json();

      if (!data.error) {
        notify = {
          message: data.data.message || "Message sent successfully",
          status: "ok",
          className: "h-96",
        };
        notifyContext?.setNotify(notify);

        setTaskStatus((prevStatus) => ({
          ...prevStatus,
          [taskName]: true,
        }));
      } else {
        notify = {
          message: data.data.message || "Error sending message",
          status: "error",
          className: "h-96",
        };
        notifyContext?.setNotify(notify);
        throw new Error(data.data.message || "An error occurred");
      }
    } catch (error: any) {
      console.error("Error sending message:", error);

      notify = {
        message: error.message || "Error sending message",
        status: "error",
        className: "h-96",
      };
      notifyContext?.setNotify(notify);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TopControl name="airdrop" />
      <div className="px-2">
        <div className="max-w-[660px] mx-auto flex flex-col items-center justify-center">
          <h1 className="text-white text-xs font-normal">AirDrop Tasks</h1>
          <p className="text-center text-xs font-normal text-[#677496] mt-3">
            Partnerships are on their way. Tasks will appear below. Complete
            them to participate in the Airdrop.
          </p>
          <p className="text-center text-xs font-bold text-[#677496] mt-3">
            The more BILLIES you collect, the bigger the airdrop value will be!
          </p>
        </div>

        <div className="max-w-[750px] mx-auto">
          <h1 className="text-center text-white text-xs font-bold mt-5">
            Tasks
          </h1>

          <div className="mt-3 space-y-3">
            {/* Task 1: "WIN GAMES & DISCOUNTS" */}
            <div
              className={`bg-[#333A48B2] w-full flex items-center gap-5 rounded-lg px-5 py-2 ${
                taskStatus["WIN_GAMES_AND_DISCOUNTS"]
                  ? "cursor-not-allowed opacity-80"
                  : "cursor-pointer"
              }`}
              onClick={() =>
                !taskStatus["WIN_GAMES_AND_DISCOUNTS"] &&
                handleSendMessage("WIN_GAMES_AND_DISCOUNTS")
              }
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  !taskStatus["WIN_GAMES_AND_DISCOUNTS"] &&
                    handleSendMessage("WIN_GAMES_AND_DISCOUNTS");
                }
              }}
            >
              <img
                src="/img/airdrop/img-1.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">
                  WIN GAMES & DISCOUNTS WITH LIVECARDS
                </h2>
                <div className="flex gap-1 mt-2">
                  {taskStatus["WIN_GAMES_AND_DISCOUNTS"] ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#677496"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
                        <path d="M9 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                        <path d="M13 11v-4a4 4 0 1 1 8 0v4" />
                      </svg>
                      <p className="text-[#677496] font-bold text-xs">
                        Unlocked
                      </p>
                    </>
                  ) : (
                    <>
                      <img
                        src="/img/airdrop/lock-icon.svg"
                        className="size-3"
                        alt="Icon"
                      />
                      <p className="text-[#677496] font-bold text-xs">
                        Click to Unlock
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Task 2: "New Airdrop Partner" */}
            <div className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-lg px-5 py-2 cursor-not-allowed opacity-50">
              <img
                src="/img/airdrop/img-2.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">
                  New Airdrop Partner
                </h2>
                <div className="flex gap-1 mt-2">
                  <p className="text-[#677496] font-bold text-xs uppercase">
                    Coming soon
                  </p>
                </div>
              </div>
            </div>

            {/* Task 3: "Token Drop" */}
            <div className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-lg px-5 py-2 cursor-not-allowed opacity-50">
              <img
                src="/img/airdrop/img-3.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">Token Drop</h2>
                <div className="flex gap-1 mt-2">
                  <p className="text-[#677496] font-bold text-xs uppercase">
                    To be announced
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-modal" />
        </div>
      )}
    </>
  );
};

// const message = `
// 🎉 *Billy Rush Airdrop & Monthly Rewards!* 🎉

// Hey there, Billy Rush player! Unlock your first airdrop and start earning even more from our partner, Livecards! Here’s how:

// 1. 🖥 Go to: (https://livecards.net/account/login)
// 2. 🔗 Click on: "Continue with Telegram"
// 3. 👤 Login using the same Telegram account that you’re using for Billy Rush.

// Once you’ve logged in, you’ll receive your first sign-up airdrop! 🎁

// But it doesn’t stop there! 🚀

// Every month, Livecards will pick winners at each Billy Rush level and give away amazing prizes like games, gaming credits, subscriptions, or gift cards. 🎮

// ✨ The higher your level, the bigger the prizes! So keep playing, level up, and increase your chances of winning! ✨

// 📢 Want to know when the lucky draws are happening? Join the official Livecards Telegram channel for updates: (https://t.me/livecardsofficial)
// `;
