import React, { useState } from "react";
import { User } from "../../../models";
import { getConfig } from "../../../utils/config";

const { adminApiUrl } = getConfig();

interface CsvExporterProps {
  selectedUsers: User[];
}

const CsvExporter: React.FC<CsvExporterProps> = ({ selectedUsers }) => {
  const [isExportingSelected, setIsExportingSelected] = useState(false);
  const [isExportingAll, setIsExportingAll] = useState(false);

  const downloadCsv = async (
    userIds?: string[],
    setLoading?: (value: boolean) => void
  ) => {
    if (setLoading) setLoading(true);

    try {
      const query = userIds ? `?userIds=${userIds.join(",")}` : "";
      const response = await fetch(
        `${adminApiUrl}/admin/export-users${query}`,
        {
          method: "GET",
          headers: {
            "Admin-Token": localStorage.getItem("password") ?? "",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Failed to export CSV. Please try again."
        );
      }

      const contentDisposition = response.headers.get("Content-Disposition");
      const filenameMatch = contentDisposition?.match(/filename="(.+?)"/);
      const filename = filenameMatch ? filenameMatch[1] : "export.csv";

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting CSV:", error);
    } finally {
      if (setLoading) setLoading(false);
    }
  };

  const handleExportAll = () => {
    downloadCsv(undefined, setIsExportingAll);
  };

  const handleExportSelected = () => {
    if (selectedUsers.length === 0) {
      alert("No users selected.");
      return;
    }
    const userIds = selectedUsers.map((user) => user.tgId.toString());
    downloadCsv(userIds, setIsExportingSelected);
  };

  return (
    <div className="flex space-x-4">
      <button
        className={`bg-blue-500 text-white py-1 px-2 text-sm rounded hover:bg-blue-600 ${
          isExportingSelected || selectedUsers.length === 0
            ? "cursor-not-allowed opacity-50"
            : ""
        }`}
        onClick={handleExportSelected}
        disabled={isExportingSelected || selectedUsers.length === 0}
      >
        {isExportingSelected
          ? "Exporting Selected..."
          : "Export Selected Users"}
      </button>
      <button
        className={`bg-green-500 text-white py-1 px-2 text-sm rounded hover:bg-green-600 ${
          isExportingAll ? "cursor-not-allowed opacity-50" : ""
        }`}
        onClick={handleExportAll}
        disabled={isExportingAll}
      >
        {isExportingAll ? "Exporting All..." : "Export All Users"}
      </button>
    </div>
  );
};

export default CsvExporter;
