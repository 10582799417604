import React, { useState } from "react";
import { User } from "../../../models";
import { getConfig } from "../../../utils/config";

interface EditModalProps {
  modalUser: User;
  setModalUser: (user: User | null) => void;
  editingBalance: { [key: number]: number | undefined };
  setEditingBalance: (balance: { [key: number]: number | undefined }) => void;
  setIsError: (isError: boolean) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  refreshUsers: () => void;
}

const { adminApiUrl } = getConfig();

const EditModal: React.FC<EditModalProps> = ({
  modalUser,
  setModalUser,
  editingBalance,
  setEditingBalance,
  setIsError,
  setIsSuccess,
  refreshUsers,
}) => {
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  const updateBalance = async (tgId: number) => {
    if (!editingBalance[tgId] && editingBalance[tgId] !== 0) return;

    setUpdateLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const response = await fetch(`${adminApiUrl}/admin/users/${tgId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Admin-Token": localStorage.getItem("password") ?? "",
        },
        body: JSON.stringify({ balance: editingBalance[tgId] }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData?.message ||
          `Failed to update balance: ${response.statusText}`;
        throw new Error(errorMessage);
      }

      setIsSuccess(true);
      setIsError(false);
      setUpdateLoading(false);
      setModalUser(null);
      await refreshUsers();
    } catch (error) {
      console.error("Error updating balance:", error);
      setIsSuccess(false);
      setIsError(true);
    } finally {
      setUpdateLoading(false);
      setEditingBalance({ ...editingBalance, [tgId]: undefined });
      setModalUser(null);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-lg font-bold">Edit Balance</h2>
        <div className="mt-4">
          <label htmlFor="balance" className="block text-sm font-medium">
            Balance
          </label>
          <input
            id="balance"
            name="balance"
            type="number"
            value={editingBalance[modalUser.tgId] ?? ""}
            onChange={(e) =>
              setEditingBalance({
                ...editingBalance,
                [modalUser.tgId]: parseFloat(e.target.value),
              })
            }
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="flex justify-end space-x-2 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={() => setModalUser(null)}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => updateBalance(modalUser.tgId)}
            disabled={updateLoading}
          >
            {updateLoading ? "Updating..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
