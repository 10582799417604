import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { getConfig } from "../../utils/config";
import { useWebSocket } from "../../hooks/useWebsocket";
import { getTelegramUser } from "../../services/telegramService";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";

interface YoutubeVideo {
  videoId: string;
  title: string;
  description: string;
  publishedAt: string;
  thumbnail: string;
  channelTitle: string;
}

const { adminApiUrl } = getConfig();

const YoutubeTasks: React.FC = () => {
  const [tasks, setTasks] = useState<YoutubeVideo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [watchedVideos, setWatchedVideos] = useState<string[]>([]);
  const notifyContext = useContext(NotifyContext);
  const { webSocket } = useWebSocket();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `${adminApiUrl}/youtube/latest-videos?channelId=UCP-iBUwL5Zs8Wc_kMSTKKkQ&maxResults=2`
        );
        setTasks(response.data.videos);
      } catch (error) {
        console.error("Error fetching YouTube tasks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  const fetchWatchedVideos = async () => {
    try {
      const tgUserId = getTelegramUser().id;
      const response = await axios.get(
        `${adminApiUrl}/youtube/watched-videos/${tgUserId}`
      );

      setWatchedVideos(response.data.videos);
    } catch (error) {
      console.error("Error fetching watched videos:", error);
    }
  };
  useEffect(() => {
    fetchWatchedVideos();
  }, []);

  const handleTaskClick = async (task: YoutubeVideo, videoId: string) => {
    try {
      if (watchedVideos.includes(videoId)) {
        notifyContext?.setNotify({
          message: "This video has already been watched.",
          status: "error",
          className: "h-96",
        });
        return;
      }

      setSelectedTaskId(task.videoId);

      window.Telegram.WebApp.openLink(
        `https://www.youtube.com/watch?v=${task.videoId}`
      );

      if (webSocket) {
        const tgUserId = getTelegramUser().id;

        webSocket.emit("completeYoutubeTask", {
          userId: tgUserId,
          videoId,
        });
        fetchWatchedVideos();
      }
    } catch (error) {
      console.error("Error handling task click:", error);

      notifyContext?.setNotify({
        message: "Failed to open the video link. Please try again.",
        status: "error",
        className: "h-96",
      });
    } finally {
      setSelectedTaskId(null);
    }
  };

  useEffect(() => {
    if (webSocket) {
      webSocket.on("rewardNotification", (data: any) => {
        const { success, message } = data;
        let notify: NotifyMessage;

        notify = {
          message: success
            ? "Task completed! " + message
            : "Failed to complete task",
          status: success ? "ok" : "error",
          className: "h-96",
        };
        notifyContext?.setNotify(notify);
      });
    }
    return () => {
      if (webSocket) {
        webSocket.off("rewardNotification");
      }
    };
  }, [webSocket]);

  if (loading) {
    return (
      <div className="p-1 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm animate-pulse">
        <div className="w-full flex flex-row justify-left items-center">
          <div className="mr-1 rounded-full w-[55px] h-[55px] bg-gray-600 animate-pulse" />
          <div className="ml-5">
            <span className="text-xs font-light text-white bg-gray-600 leading-none truncate block w-[220px] h-2 animate-pulse"></span>
            <span className="flex flex-row justify-left mt-1 items-center">
              <div className="flex gap-2 items-center">
                <div className="size-2 bg-gray-600 rounded-full animate-pulse"></div>
              </div>
              <div className="ml-1 text-xs bg-gray-600 font-bold animate-pulse h-1"></div>
              <div className="text-xs text-[#677496] font-bold ml-1 animate-pulse h-1"></div>
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ul className="space-y-2">
      {tasks.map((video, index) => (
        <li key={video.videoId}>
          <button
            className={`p-1 my-2 ${
              selectedTaskId === video.videoId
                ? "bg-[#444B58B2] cursor-progress opacity-50"
                : "bg-[#333A48B2]"
            } rounded-2xl flex justify-between items-center shadow-sm cursor-pointer`}
            onClick={() => handleTaskClick(video, video.videoId)}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleTaskClick(video, video.videoId);
            }}
            tabIndex={0}
          >
            <div className="w-full flex flex-row justify-left items-center">
              {selectedTaskId === video.videoId ? (
                <div className="mr-1 rounded-full w-[55px] h-[55px] flex items-center justify-center bg-gray-600">
                  <div className="w-5 h-5 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="mr-1 rounded-full w-[55px] h-[55px] object-cover"
                />
              )}
              <div className="ml-5">
                <span className="text-xs font-light text-white leading-none truncate block w-[220px]">
                  {video.title}
                </span>
                <span className="flex flex-row justify-left mt-1 items-center">
                  <div className="flex gap-2 items-center">
                    <div className="size-2 bg-[#E0AE10] rounded-full"></div>
                    <img src="./img/coin.png" className="w-5 h-5" alt="Coin" />
                  </div>
                  <div className="ml-1 text-xs text-[#E0AE10] font-bold">
                    +5000
                  </div>
                  <div className="text-xs text-[#677496] font-bold ml-1">
                    Billies
                  </div>
                </span>
              </div>
            </div>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default YoutubeTasks;
