import { useContext, useEffect } from "react";
import { useWebSocket } from "../hooks/useWebsocket";
import { NotifyContext } from "../contexts/NotifyContext";
import { useUser } from "../hooks/useUser";
import { DataContext } from "../contexts/DataContext";

export const ComboGame = () => {
  const notifyContext = useContext(NotifyContext);
  const dataContext = useContext(DataContext);
  const { user } = useUser();

  const unlockedCombos = user?.currentComboCompletions || [];
  const totalCombos = 3;

  return (
    <div className="flex justify-center gap-4 my-2">
      {unlockedCombos.map((b) => {
        const business = dataContext?.businesses.find((cb) => cb.id == b);
        return (
          <div
            key={b}
            className="rounded-3xl bg-gradient-to-b from-[#323947CC] to-[#43547ACC] w-[110px] h-[120px] py-3 px-5"
          >
            <img
              className="rounded-lg w-full h-[70%] object-cover object-top"
              src={business?.avatarUrl}
            />
            <p className="text-[#C0C9DD] uppercase text-[10px] mt-2 text-center">
              {business?.name}
            </p>
          </div>
        );
      })}
      {Array.from({ length: totalCombos - unlockedCombos.length }).map(
        (_, index) => (
          <div
            key={`combo-placeholder-${index}`}
            className="rounded-3xl bg-gradient-to-b from-[#323947CC] to-[#43547ACC] flex items-start justify-center text-white w-[110px] h-[120px] py-3 px-5"
          >
            <img
              src="./img/secret.png"
              className="rounded-lg w-full h-[70%] object-cover object-top"
            />
          </div>
        )
      )}
    </div>
  );
};
