import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";

const { adminApiUrl } = getConfig();

const AdminAirdropMessage: React.FC = () => {
  const [newMessage, setNewMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMessage = async () => {
    try {
      const response = await fetch(`${adminApiUrl}/admin/air-drop-message`);
      const data = await response.json();
      if (data.statusCode !== 200) {
        setNewMessage("");
      } else {
        setNewMessage(data.message);
      }
    } catch (error) {
      console.error("Error fetching message:", error);
    }
  };
  useEffect(() => {
    fetchMessage();
  }, []);

  const handleUpdateMessage = async () => {
    if (!newMessage.trim()) return;
    setIsLoading(true);
    try {
      const response = await fetch(`${adminApiUrl}/admin/air-drop-message`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: newMessage }),
      });

      const data = await response.json();
      setNewMessage(data.data.content);
      fetchMessage();
    } catch (error) {
      console.error("Error updating message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full mx-auto">
      <h2 className="text-2xl font-semibold text-white mb-4">
        AirDrop Message
      </h2>

      <div className="bg-white shadow-md rounded p-6">
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Enter new message"
          rows={10}
          cols={50}
          className="w-full p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out mb-4 text-gray-700"
        ></textarea>
      </div>
      <button
        onClick={handleUpdateMessage}
        disabled={isLoading || !newMessage.trim()}
        className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-600 disabled:bg-gray-400 transition duration-200 ease-in-out mt-5"
      >
        {isLoading ? "Saving..." : "Save Message"}
      </button>
    </div>
  );
};

export default AdminAirdropMessage;
