import React, { useState, useEffect, useMemo, useContext } from "react";
import { useWebSocket } from "../../hooks/useWebsocket";
import { useUser } from "../../hooks/useUser";
import { BoostModal } from "./BoostModal";
import { useSettings } from "../../hooks/useSettings";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";
import { TopControl } from "../../components/TopControl";
import { formatNumber, formatNumberWithCommas } from "../../utils/formatNumber";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

type Boost = {
  id: number;
  img: string;
  title: string;
  description: string;
};

const boosts: Boost[] = [
  {
    id: 0,
    img: "./img/energy_ext.png",
    title: "FULL ENERGY",
    description:
      "Recharge your energy to the maximum and do another round of mining!",
  },
  {
    id: 1,
    img: "./img/multitap_ext.png",
    title: "Multitap",
    description: "Increase the amount of BILLIES you can earn per tap",
  },
  {
    id: 2,
    img: "./img/energylimit.png",
    title: "Energy limit",
    description: "Increases the amount of energy available to the user",
  },
];

export const Boosts = () => {
  const { webSocket } = useWebSocket();
  const { user } = useUser();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBoost, setSelectedBoost] = useState<Boost | null>(null);
  const { startClickUpgradeCost, startEnergyUpgradeCost } = useSettings();
  const notifyContext = useContext(NotifyContext);
  const { maxClickLevel, maxEnergyLevel, fullEnergyBoostPerDay } =
    useSettings();
  const navigate = useNavigate();

  const [isEnergyUpgrading, setEnergyUpgrading] = useState(false);
  const [isEnergyRestoring, setEnergyRestroring] = useState(false);
  const [isClickUpgrading, setClickUpgrading] = useState(false);

  useEffect(() => {
    if (webSocket) {
      const handleBoostActivated = ({ success, message }: any) => {
        const notify: NotifyMessage = {
          status: success ? "ok" : "error",
          message: message || "The energy has been restored",
          className: "h-96",
        };
        setEnergyRestroring(false);
        notifyContext?.setNotify(notify);
      };

      webSocket.on("boostActivated", handleBoostActivated);

      webSocket.on("clickPowerUpgraded", ({ success }) => {
        setClickUpgrading(false);

        const notify: NotifyMessage = {
          status: success ? "ok" : "error",
          message: success
            ? "The power of your click has been enhanced"
            : "The power of your click was not increased",
          className: "h-96",
        };
        notifyContext?.setNotify(notify);
      });

      webSocket.on("energyUpgraded", ({ success, message }) => {
        setEnergyUpgrading(false);
        const notify: NotifyMessage = {
          status: success ? "ok" : "error",
          message: success
            ? message || "Your energy level has been enhanced"
            : message || "Your energy level was not enhanced",
          className: "h-96",
        };
        notifyContext?.setNotify(notify);
      });
      return () => {
        webSocket.off("boostActivated", handleBoostActivated);
        webSocket.off("clickPowerUpgraded");
      };
    }
  }, [webSocket]);

  useEffect(() => {
    if (typeof window.Telegram.WebApp !== "undefined") {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(function () {
        window.history.back();
      });
    }
    return () => {
      if (typeof window.Telegram.WebApp !== "undefined") {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, []);

  const activateFullEnergyBoost = () => {
    if (webSocket) {
      if (fullEnergyActivates < fullEnergyBoostPerDay) {
        setEnergyRestroring(true);
        webSocket.emit(
          "activateBoost",
          JSON.stringify([user?.tgId, "fullEnergyBoost"])
        );
      } else {
        const notify: NotifyMessage = {
          status: "error",
          message: "You can't restore energy today",
          className: "h-96",
        };
        notifyContext?.setNotify(notify);
      }
    }
  };

  const improveClick = () => {
    if (!!user && user?.clickPower >= maxClickLevel) {
      const notify: NotifyMessage = {
        status: "unknown",
        message: "You have reached the maximum clicker level",
        className: "h-96",
      };
      notifyContext?.setNotify(notify);
      return;
    }
    if (
      webSocket &&
      startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1) <=
        (user?.balance || 0)
    ) {
      setClickUpgrading(true);
      webSocket.emit("upgradeClick", user?.tgId);
    } else {
      const notify: NotifyMessage = {
        status: "error",
        message: "You don't have enough balance",
        className: "h-96",
      };
      notifyContext?.setNotify(notify);
    }
  };

  const upgradeEnergy = () => {
    if (!!user?.energyLevel && user.energyLevel >= maxEnergyLevel) {
      const notify: NotifyMessage = {
        status: "unknown",
        message: "You have reached the maximum energy level",
        className: "h-96",
      };
      notifyContext?.setNotify(notify);
      return;
    }

    if (
      webSocket &&
      startEnergyUpgradeCost * 2 ** ((user?.energyLevel || 2) - 1) <=
        (user?.balance || 0)
    ) {
      webSocket.emit("upgradeEnergy", user?.tgId);
      setEnergyUpgrading(true);
    } else {
      const notify: NotifyMessage = {
        status: "error",
        message: "You don't have enough balance",
        className: "h-96",
      };
      notifyContext?.setNotify(notify);
    }
  };

  const energyDisabled = useMemo(() => {
    if (user) {
      return (
        Date.now() - user?.lastFullEnergyTimestamp < 1000 * 60 * 60 * 24 &&
        user?.fullEnergyActivates >= 3
      );
    } else {
      return true;
    }
  }, [user]);

  const fullEnergyActivates = useMemo(() => {
    if (user) {
      if (Date.now() - user?.lastFullEnergyTimestamp > 1000 * 60 * 60 * 24) {
        return 0;
      }
    }
    return user?.fullEnergyActivates || 0;
  }, [user]);

  const onClose = () => {
    setModalOpen(false);
  };
  const onPurchase = () => {
    // onClose();

    switch (selectedBoost?.id) {
      case 0:
        activateFullEnergyBoost();
        break;
      case 1:
        improveClick();
        break;
      case 2:
        upgradeEnergy();
        break;
    }
  };

  const price = useMemo(() => {
    if (selectedBoost?.id == 0) {
      return "Free";
    }
    if (selectedBoost?.id == 1) {
      return formatNumber(
        startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1)
      );
    }
    if (selectedBoost?.id == 2) {
      return formatNumber(
        startEnergyUpgradeCost * 2 ** ((user?.energyLevel || 2) - 1)
      );
    }
  }, [selectedBoost]);

  return (
    <>
      <TopControl name="boosts" />
      <div className="p-5 pt-0 rounded-lg max-w-md mx-auto">
        <div className="flex flex-col justify-center items-center my-6">
          <p className="text-base text-white">Your Balance:</p>
          <div className="flex justify-center gap-4 pt-5 pb-3">
            <img src="./img/coin.png" className="w-10" />
            <div className="text-3xl font-extrabold">
              {formatNumberWithCommas(user?.balance || 0)}
            </div>
          </div>
          {/* <div className="mt-2 text-sm text-[#54B0FE]">How a boost works?</div> */}
          <div className="mt-2 text-sm text-[#54B0FE] flex items-center gap-2">
            <div className="flex items-center gap-2" id="boost-tip">
              <span>How a boost works?</span>
              <button
                className="w-5 h-5 flex items-center justify-center rounded-full bg-[#273148] text-white text-xs font-bold"
                aria-label="Boost Info"
              >
                i
              </button>
            </div>
            <Tooltip
              anchorId="boost-tip"
              place="right"
              children={
                <div className="text-left">
                  <p>Boost benefits:</p>
                  <ul className="list-disc ml-4">
                    <li>Users can avail full energy.</li>
                    <li>Users can increase their multitap level.</li>
                    <li>Users can increase their energy limit.</li>
                  </ul>
                </div>
              }
            />
          </div>
        </div>
        <div
          className="no-scrollbar"
          style={{ maxHeight: window.innerHeight - 330, overflowY: "scroll" }}
        >
          <div className="mt-2 flex flex-col gap-4">
            <div className="uppercase text-center">Free Daily Boosters:</div>
            <div
              className={
                "relative flex justify-start items-center bg-[#333A48B2] rounded-2xl px-6 py-2 gap-6 " +
                (isEnergyRestoring ? "opacity-50" : "opacity-100")
              }
              onClick={() => {
                setModalOpen(true);
                setSelectedBoost(boosts[0]);
              }}
            >
              <img src="./img/energy.png" className="w-6" />
              <div className="flex flex-col justify-center">
                <div className="uppercase font-light">Full energy</div>
                <div className="text-[#677496]">
                  {/* {3 - fullEnergyActivates}/{fullEnergyBoostPerDay} available */}
                  {fullEnergyBoostPerDay - fullEnergyActivates}/
                  {fullEnergyBoostPerDay} available
                </div>
              </div>
            </div>

            <div className="mt-4 text-center uppercase">Boosters</div>
            <div
              className={
                "relative flex justify-start items-center bg-[#333A48B2] rounded-2xl px-6 py-2 gap-6 " +
                (isClickUpgrading ? "opacity-50" : "opacity-100")
              }
              onClick={() => {
                setModalOpen(true);
                setSelectedBoost(boosts[1]);
              }}
            >
              <img src="./img/multitap.png" className="w-6" />
              <div className="flex flex-col justify-center">
                <div className="uppercase font-light">Multitap </div>
                <div className="flex items-center gap-2">
                  <img src="./img/coin.png" className="w-5 h-5" />
                  <div className="text-[#E0AE10]">
                    {formatNumber(
                      startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1)
                    )}
                  </div>
                  <div className="w-2 h-2 rounded-full bg-[#677496]" />
                  <div className="text-[#677496] font-bold">
                    {user?.clickPower} lvl
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                "relative flex justify-start items-center bg-[#333A48B2] rounded-2xl px-6 py-2 gap-6 " +
                (isEnergyUpgrading ? "opacity-50" : "opacity-100")
              }
              onClick={() => {
                setModalOpen(true);
                setSelectedBoost(boosts[2]);
              }}
            >
              <img src="./img/energylimit.png" className="w-6" />
              <div className="flex flex-col justify-center">
                <div className="uppercase font-light">Energy limit</div>
                <div className="flex items-center gap-2">
                  <img src="./img/coin.png" className="w-5 h-5" />
                  <div className="text-[#E0AE10]">
                    {formatNumber(
                      startEnergyUpgradeCost *
                        2 ** ((user?.energyLevel || 2) - 1)
                    )}
                  </div>
                  <div className="w-2 h-2 rounded-full bg-[#677496]" />
                  <div className="text-[#677496] font-bold">
                    {user?.energyLevel} lvl
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && selectedBoost && (
          <BoostModal
            img={selectedBoost.img}
            title={selectedBoost.title}
            onClose={onClose}
            onPurchase={onPurchase}
            description={selectedBoost.description}
            price={price}
          />
        )}
      </div>
    </>
  );
};
