import { useMemo, useEffect, useContext, useState } from "react";
import { useUser } from "../hooks/useUser";
import { useWebSocket } from "../hooks/useWebsocket";
import { NotifyContext } from "../contexts/NotifyContext";
import DailyReward from "./DailyRewardPopUp/DailyReward";

export const DailyOffer = () => {
  const { user } = useUser();
  const [showCalendar, setShowCalendar] = useState(false);
  const dateUnlock = useMemo(() => {
    if (!user || !user.lastDailyRewardTimestamp) {
      return {
        hours: 0,
        minutes: 0,
      };
    }

    let timestamp = user.lastDailyRewardTimestamp;

    if (typeof timestamp === "string") {
      timestamp = new Date(timestamp).getTime();
    }

    const hoursToUnlock = 24 - (Date.now() - timestamp) / 1000 / 60 / 60;
    const roundedHour = Math.max(0, Math.floor(hoursToUnlock));
    const roundedMinute = Math.max(
      0,
      Math.floor((hoursToUnlock - Math.floor(hoursToUnlock)) * 60)
    );

    return {
      hours: roundedHour,
      minutes: roundedMinute,
    };
  }, [user]);

  const dailyDisabled = useMemo(() => {
    if (user) {
      return Date.now() - user.lastDailyRewardTimestamp < 1000 * 60 * 60 * 24;
    } else {
      return true;
    }
  }, [user]);

  return (
    <>
      <div
        className={
          "p-4 my-2 rounded-3xl flex flex-col justify-center items-center relative"
          // (dailyDisabled ? "relative opacity-50" : "relative")
        }
        style={{
          background:
            "linear-gradient(180deg, rgba(50, 57, 71, 0.8) 0%, rgba(67, 84, 122, 0.8) 100%)",
        }}
        onClick={() => setShowCalendar(!showCalendar)}
      >
        <img className="w-8" src="./img/calendar.png" />
        <div className="uppercase text-xs text-[#C0C9DD] mt-5">
          Daily Reward
        </div>
        <div className="flex items-center text-xs gap-2 p-1 bg-[#12131733] rounded-full mt-2 text-[#C0C9DD]">
          <div className="bg-[#273148] rounded-full p-1 w-6 h-6 flex items-center justify-center">
            {dateUnlock?.hours}
          </div>
          <div>:</div>
          <div className="bg-[#273148] rounded-full p-1 w-6 h-6 flex items-center justify-center">
            {dateUnlock?.minutes}
          </div>
        </div>
      </div>
      <DailyReward
        dateUnlock={dateUnlock}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
      />
    </>
  );
};
