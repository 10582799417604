import React, { useState } from "react";
import { getConfig } from "../../../utils/config";

const { adminApiUrl } = getConfig();

export const AdminBroadcast = () => {
  const [message, setMessage] = useState("");
  const [target, setTarget] = useState("all");
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState<string | null>(null);

  const sendMessage = async () => {
    setSending(true);
    setStatus(null);
    try {
      const response = await fetch(`${adminApiUrl}/admin/broadcast`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Admin-Token": localStorage.getItem("password") || "",
        },
        body: JSON.stringify({ message, target }),
      });

      if (response.ok) {
        setStatus("Message sent successfully!");
      } else {
        setStatus("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending broadcast:", error);
      setStatus("An error occurred.");
    } finally {
      setSending(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-white mb-4">
          Broadcast Message to Users
        </h2>
        <select
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          className="p-1 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out text-gray-700"
        >
          <option value="all">Global</option>
          <option value="unlocked">Unlocked Livecards Airdrop</option>
          <option value="locked">Locked Livecards Airdrop</option>
        </select>
      </div>
      <div className="bg-white shadow-md rounded p-6">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
          rows={5}
          cols={50}
          className="w-full p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out mb-4 text-gray-700"
        />
      </div>
      <button
        onClick={sendMessage}
        disabled={sending || !message.trim()}
        className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-600 disabled:bg-gray-400 transition duration-200 ease-in-out mt-5"
      >
        {sending ? "Sending..." : "Send Broadcast"}
      </button>
      {status && <p>{status}</p>}
    </div>
  );
};
